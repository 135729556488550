import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import Header from "../components/header";
import Seo from "../components/seo";

// markup
const IndexPage = () => {
  return (
    <div>
      <Seo title="TOP - 日和亭小幡楼トップ" />
      <main id="wrap">
        <Header />
        <article className="main-col" role="main">
          <div className="main-wrap">
            <div className="key-visual">
              <div className="video">
                <iframe
                  src="https://www.youtube.com/embed/FntNG38LeOg?vq=highres&controls=0&iv_load_policy=1&modestbranding=0&autoplay=1&mute=1&loop=1&playlist=FntNG38LeOg"
                  title="Obataro"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div className="lead-text t-center">
              <h2>
                多世代が自然に集まるカジュアルな空間で、
                <br />
                歴史を感じながら心地よい時間を
              </h2>
              <p>
                江戸時代から明治期にかけて、「北前船」と呼ばれる商船群が活躍し、交易で発展を遂げた港町・酒田。酒田港に面した日和山に明治・大正期に建てられた料亭をリノベーションし、2021年に「日和山小幡楼（ひよりやまおばたろう）」をオープンいたしました。
                <br />
                <br />
                安心安全な地域性商品や、地産の美味しい「畑の恵み」「山の恵み」を食の軸としています。ご家族やご友人と気軽に集うコミュニティの場、庄内に住まう人や観光で訪れる人が、より一層酒田の魅力を感じていただけるよう、伝統を守りながら活力ある変化をし続ける拠点を目指します。
                <br />
                <br />
                江戸の時代、船乗りたちが港の天候を予想するために登った日和山からの眺めは、幾時代を経た今も変わらずここにあります。
                <br />
                コーヒーを片手に、時を越えた景色と共に…
              </p>
              {/* <p className="green sp t-left">
                【年末年始営業】<br />
                  12月30日:通常営業<br />
                  12月31日:時短営業<br />
                  - 9:00～15:00 LO14:30、日和亭:休業<br />
                  1月1日:時短営業<br />
                  - 9:00～15:00 LO14:30、日和亭:休業<br />
                  1月2日:通常営業
              </p> */}
              {/* <p className="green sp">
                12月30,31日、1月1日は
                <br />
                休業とさせて頂きます
              </p> */}
            </div>
            <section className="c-contents">
              <div className="c-linklist">
                <Link to="/bakerycafe/">
                  <p>
                    <StaticImage
                      layout="constrained"
                      alt="ヒヨリベーカリー＆カフェロゴ"
                      src="../images/top/link-logo-bakery.svg"
                    />
                  </p>
                  <span className="pc">
                    <StaticImage
                      layout="constrained"
                      alt="ヒヨリベーカリー＆カフェ"
                      src="../images/top/link-bakery.jpg"
                    />
                  </span>
                  <span className="sp">
                    <StaticImage
                      layout="constrained"
                      alt="ヒヨリベーカリー＆カフェ"
                      src="../images/top/link-bakery_sp.jpg"
                    />
                  </span>
                </Link>
                <Link to="/hiyoritei/">
                  <p>
                    <StaticImage
                      layout="constrained"
                      alt="日和亭ロゴ"
                      src="../images/top/link-logo-hiyoritei.svg"
                    />
                  </p>
                  <span className="pc">
                    <StaticImage
                      layout="constrained"
                      alt="日和亭外観"
                      src="../images/top/link-hiyoritei.jpg"
                    />
                  </span>
                  <span className="sp">
                    <StaticImage
                      layout="constrained"
                      alt="日和亭外観"
                      src="../images/top/link-hiyoritei_sp.jpg"
                    />
                  </span>
                </Link>
                <Link to="/access/">
                  <p>ACCESS</p>
                  <StaticImage
                    layout="constrained"
                    alt="小幡楼へのアクセス"
                    src="../images/top/link-access.jpg"
                    className="pc"
                  />
                  <StaticImage
                    layout="constrained"
                    alt="小幡楼へのアクセス"
                    src="../images/top/link-access_sp.jpg"
                    className="sp"
                  />
                </Link>
                <Link to="/history/">
                  <p>小幡の歴史</p>
                  <StaticImage
                    layout="constrained"
                    alt="小幡の歴史"
                    src="../images/top/link-history.jpg"
                    className="pc"
                  />
                  <StaticImage
                    layout="constrained"
                    alt="小幡の歴史"
                    src="../images/top/link-history_sp.jpg"
                    className="sp"
                  />
                </Link>
              </div>
            </section>
          </div>
          <footer>
            <div className="c-footer__wrap">
              <ul>
                <li>
                  <Link className="menu-item store-info" to="/">
                    日和亭 小幡楼
                  </Link>
                </li>
                <li>
                  <Link className="menu-item store-info" to="/bakerycafe/">
                    ヒヨリベーカリー＆カフェ
                  </Link>
                </li>
                <li>
                  <Link className="menu-item store-info" to="/hiyoritei/">
                    日和亭
                  </Link>
                </li>
                <li>
                  <Link className="menu-item store-info" to="/access/">
                    アクセス
                  </Link>
                </li>
                <li>
                  <Link className="menu-item store-info" to="/history/">
                    割烹小幡の歴史
                  </Link>
                </li>
              </ul>
            </div>
            <small className="sans">&copy; 2021 obatarou</small>
          </footer>
        </article>
      </main>
    </div>
  );
};

export default IndexPage;
